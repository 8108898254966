import { useEffect, useState } from 'react';
import { ContentEntitySys, FieldAppSDK, SidebarAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { Button } from '@contentful/f36-button';
import { Badge, BadgeVariant } from '@contentful/f36-badge';
import { Box, Flex, List, Note, RelativeDateTime, Spinner, Stack } from '@contentful/f36-components';
import { Text } from '@contentful/f36-typography';
import { ValidationResult } from '../types/ValidationResult';
import ExtendedInformation from './ExtendedInformation';

type EntryStatus = 'DRAFT' | 'PUBLISHED' | 'CHANGED';

export type CustomPublisButtonProps = {
  onValidate?: () => Promise<ValidationResult>;
};

const CustomPublishButton = (props: CustomPublisButtonProps): JSX.Element => {
  const sdk = useSDK<SidebarAppSDK>();

  const [entryEntitySys, setEntryEntitySys] = useState<ContentEntitySys>(sdk.entry.getSys());
  const [saving, setSaving] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  let fields: FieldAppSDK[] = [];

  for (let key in sdk.entry.fields) {
    const sdkCast: FieldAppSDK = {
      sdk,
      field: sdk.entry.fields[key].getForLocale(sdk.locales.default),
    } as unknown as FieldAppSDK;

    fields.push(sdkCast);
  }

  useEffect(() => {
    for (let key in sdk.entry.fields) {
      for (let localeKey in sdk.entry.fields[key].locales) {
        const locale = sdk.entry.fields[key].locales[localeKey];
        sdk.entry.fields[key].getForLocale(locale).onValueChanged(startSaving);
      }
    }

    sdk.entry.onSysChanged((sys: ContentEntitySys) => {
      setEntryEntitySys(sys);
      setSaving(false);
    });

    sdk.window.startAutoResizer();
  }, [sdk.entry, sdk.window]);

  const startSaving = () => {
    setSaving(true);
  };

  const validate = async (): Promise<ValidationResult> => {
    if (!props.onValidate) return { isValid: true, validationErrors: [] };
    else {
      return await props.onValidate();
    }
  };

  const buttonClick = async (status: EntryStatus) => {
    switch (status) {
      case 'DRAFT':
      case 'CHANGED':
        setValidationErrors([]);
        const validationResult = await validate();

        if (validationResult.isValid) {
          await sdk.entry.publish();
        } else {
          setValidationErrors(validationResult.validationErrors);
          sdk.notifier.error('Discovery Page cannot be published. Check the errors and try again.');
        }
        break;
      case 'PUBLISHED':
        await sdk.entry.unpublish();
        break;
    }
  };

  const hasErrors = (): boolean => {
    return fields.some((f) => f.field.getSchemaErrors().length > 0);
  };

  const renderButton = (status: EntryStatus): JSX.Element => {
    return (
      <Button isDisabled={hasErrors()} variant="positive" isFullWidth={true} onClick={() => buttonClick(status)}>
        {
          {
            DRAFT: 'Publish',
            PUBLISHED: 'Unpublish',
            CHANGED: 'Publish changes',
          }[status]
        }
      </Button>
    );
  };

  const renderStatus = (status: EntryStatus): JSX.Element => {
    let variant: BadgeVariant = 'warning';
    switch (status) {
      case 'PUBLISHED':
        variant = 'positive';
        break;
      case 'CHANGED':
        variant = 'primary';
        break;
    }

    return <Badge variant={variant}>{status.toString()}</Badge>;
  };

  const getEntryStatus = (): EntryStatus => {
    if (saving) {
      return 'CHANGED';
    }

    if (entryEntitySys && entryEntitySys.publishedVersion) {
      return entryEntitySys.publishedAt === entryEntitySys.updatedAt ? 'PUBLISHED' : 'CHANGED';
    }

    return 'DRAFT';
  };

  const entryStatus: EntryStatus = getEntryStatus();

  return entryEntitySys ? (
    <Stack flexDirection="column" spacing="spacingS">
      <Flex fullWidth={true} flexBasis={0} justifyContent="space-between">
        <Box>Current</Box>
        {renderStatus(entryStatus)}
      </Flex>
      <Flex fullWidth={true}>{renderButton(entryStatus)}</Flex>
      <Stack flexDirection="row" spacing="spacing2Xs" alignContent="flex-start" fullWidth={true}>
        {saving ? (
          <>
            <Text>Saving</Text>
            <Spinner variant="default" size="medium" />
          </>
        ) : (
          <>
            <Text>Last saved</Text>
            <RelativeDateTime prefix="Last saved" date={entryEntitySys.updatedAt} />
          </>
        )}
      </Stack>
      {validationErrors?.length > 0 && (
        <Flex>
          <Note title="Validation errors" variant="negative">
            <List style={{ padding: 0 }}>
              {validationErrors.map((v: string, i: number) => {
                return <List.Item key={`validation-error-${i}`}>{v}</List.Item>;
              })}
            </List>
          </Note>
        </Flex>
      )}
    </Stack>
  ) : (
    <>
      <Text>Loading</Text>
      <Spinner variant="default" size="medium" />
    </>
  );
};

export default CustomPublishButton;
