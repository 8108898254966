interface Site {
  businessOwner: string;
  canonicalDomain: string;
  country: string;
  primaryLanguage: string;
  supportedLanguages: string[];
}

export const getDomains = async (tenant: string) => {
  const liveSiteRawData: any = await (await fetch('https://livesite.jetstream.vpsvc.com/')).json();

  const sites: Record<string, Site> = liveSiteRawData.tenant[tenant].sites;

  const domainsDictionary: Record<string, string> = Object.keys(sites).reduce(
    (result: Record<string, string>, siteKey: string) => {
      const site = sites[siteKey];
      const { supportedLanguages, canonicalDomain } = site;
      supportedLanguages.forEach((language: string) => {
        result[language] = canonicalDomain;
      });

      return result;
    },
    {}
  );

  return domainsDictionary;
};
