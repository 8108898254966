import UrlServiceClient from '../providers/UrlServiceClient';
import {
  DiscoveryPage,
  isDiscoveryPageAvailable,
  isDiscoveryPageAvailableThroughExperiment,
} from '../types/DiscoveryPage';
import { UrlServiceResponse } from '../types/UrlServiceResponse';
import { ValidationResult } from '../types/ValidationResult';

export class SlugValidationService {
  private validationResult: ValidationResult = {
    isValid: true,
    validationErrors: [],
  };

  public constructor(
    private tenant: string,
    private slugValidationPattern: string,
    private urlServiceProvider: UrlServiceClient
  ) {}

  public async validate(discoveryPageData: DiscoveryPage): Promise<ValidationResult> {
    let invalidSlugs: Record<string, string>[] = [];

    const localesToValidate = Object.keys(discoveryPageData.availability).filter((r: string) =>
      isDiscoveryPageAvailable(discoveryPageData, r)
    );

    const contextsToValidate = Object.keys(discoveryPageData.contextVariants).filter((r: string) =>
      isDiscoveryPageAvailableThroughExperiment(discoveryPageData, r)
    );

    var combinedLocalesToValidate = localesToValidate.concat(
      contextsToValidate.filter((item) => localesToValidate.indexOf(item) < 0)
    );

    const validationPromises: Promise<{ locale: string; slug: string; isValid: boolean; reason: string }>[] = [];

    for (let locale of combinedLocalesToValidate) {
      validationPromises.push(this.validateSlug(locale, discoveryPageData));
    }

    const validationResults = await Promise.all(validationPromises);

    invalidSlugs = validationResults
      .filter((v) => !v.isValid)
      .map((i) => {
        return { locale: i.locale, slug: i.slug, reason: i.reason };
      });

    this.validationResult.isValid = invalidSlugs.length === 0;
    this.validationResult.validationErrors = invalidSlugs.map((s) => `[${s.locale}]: ${s.reason}`);

    return this.validationResult;
  }

  private isSlugEmpty = (slug: string): boolean => {
    return !slug || slug === '/';
  };

  private validateSlug = async (locale: string, discoveryPageData: DiscoveryPage): Promise<any> => {
    if (discoveryPageData.categoryId) {
      if (!discoveryPageData.categoryUrl[locale]) {
        return { locale, slug: 'no-slug', isValid: false, reason: 'The selected category does not have a valid URL' };
      }
    }

    const slug: string = discoveryPageData.slugs[locale];

    if (this.isSlugEmpty(slug)) {
      if (!discoveryPageData.categoryUrl[locale]) {
        return { locale, slug: 'no-slug', isValid: false, reason: 'either a category or a slug must be selected' };
      }

      // TODO: review what happens if url in use
      return { locale, slug: 'no-slug', isValid: true };
    }

    let isValidSlug = await this.isUrlValid(locale, discoveryPageData);
    const result = { locale, slug: slug, isValid: isValidSlug.isValid, reason: isValidSlug.reason };

    return result;
  };

  private isUrlValid = async (
    locale: string,
    discoveryPageData: DiscoveryPage
  ): Promise<{ isValid: boolean; reason?: string }> => {
    const slug: string = discoveryPageData.slugs[locale];
    const isPatternValid = this.isSlugPatternValid(slug);

    if (!isPatternValid) {
      return { isValid: false, reason: `slug '${slug}' has a wrong format` };
    }

    if (slug === '/') {
      const isValid = this.isValidSlashURL(discoveryPageData.categoryUrl);
      return { isValid: isValid, reason: isValid ? '' : 'either a category or a slug must be selected' };
    }

    if (isDiscoveryPageAvailableThroughExperiment(discoveryPageData, locale)) {
      return { isValid: false, reason: `experiments are only supported on discovery pages without a slug` };
    }

    if (discoveryPageData.isProduction) {
      const response: UrlServiceResponse = await this.urlServiceProvider.getUrlId(
        this.tenant,
        locale,
        `${discoveryPageData.categoryUrl[locale] || ''}/${slug}`
      );

      const discoveryPageId = discoveryPageData.discoveryPageIdFromSnapshot
        ? discoveryPageData.discoveryPageIdFromSnapshot
        : discoveryPageData.discoveryId;
      const isValid = response.available || response.id === `discovery:${discoveryPageId}`;

      return {
        isValid: isValid,
        reason: isValid
          ? ''
          : `the ${response.type} with ID '${response.id} 'is already using the URL '${response.url}'`,
      };
    } else {
      return { isValid: true, reason: `in development the validation in the url service is not considered` };
    }
  };

  private isSlugPatternValid = (slug: string): boolean => {
    var regExp = new RegExp(this.slugValidationPattern);
    return regExp.test(slug);
  };

  private isValidSlashURL = (urlBasePath: any) => {
    if (!urlBasePath) {
      return false;
    } else {
      return true;
    }
  };
}
