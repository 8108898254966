import { Availability } from '../constants';

export type DiscoveryPage = {
  discoveryId: string;
  categoryId: string;
  categoryUrl: { [locale: string]: string };
  availability: { [locale: string]: string };
  contextVariants: { [locale: string]: string };
  slugs: { [locale: string]: string };
  discoveryPageIdFromSnapshot?: string;
  isProduction?: boolean;
};

export interface DiscoveryPageEntry {
  discoveryId: string;
}

export const isDiscoveryPageAvailable = (discoveryPage: DiscoveryPage, locale: string): boolean => {
  return (
    discoveryPage.availability[locale] === Availability.PUBLIC ||
    discoveryPage.availability[locale] === Availability.INTERNAL_TEST
  );
};

export const isDiscoveryPageAvailableThroughExperiment = (discoveryPage: DiscoveryPage, locale: string): boolean => {
  return (
    discoveryPage.contextVariants[locale] === Availability.PUBLIC ||
    discoveryPage.contextVariants[locale] === Availability.INTERNAL_TEST
  );
};
