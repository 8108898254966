export enum FieldNames {
  DISCOVERY_ID = 'discoveryId',
  MERCHANDISING_CATEGORY = 'merchandisingCategory',
  PAGE_AVAILABILITY = 'pageAvailability',
  SLUG = 'slug',
}

export enum Availability {
  PUBLIC = 'Public',
  INTERNAL_TEST = 'InternalTest',
}
