import { Note, Stack, Accordion, Caption, Box, EntityList, TextLink } from '@contentful/f36-components';
import {
  DiscoveryPage,
  isDiscoveryPageAvailable,
  isDiscoveryPageAvailableThroughExperiment,
} from '../types/DiscoveryPage';
import { getDomains } from '../providers/DomainProvider';
import { useEffect, useState } from 'react';
import { SidebarAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';

export type ExtendedInformationProps = {
  tenant: string;
  discoveryPageData: DiscoveryPage;
};

const ExtendedInformation = (props: ExtendedInformationProps) => {
  const sdk = useSDK<SidebarAppSDK>();
  const [domains, setDomains] = useState<Record<string, string>>({});
  useEffect(() => {
    (async () => {
      const domains = await getDomains(props.tenant);
      setDomains(domains);
    })();
  }, []);

  const isSlugEmpty = (locale: string): boolean => {
    return props.discoveryPageData.slugs[locale] && props.discoveryPageData.slugs[locale] !== '/' ? false : true;
  };

  const getUrlId = (locale: string): string => {
    if (!isSlugEmpty(locale)) {
      return `discovery:${props.discoveryPageData.discoveryId}`;
    }
    if (props.discoveryPageData.categoryId) {
      return props.discoveryPageData.categoryId;
    }
    return 'invalid';
  };

  const getType = (locale: string): string => {
    if (!isSlugEmpty(locale)) {
      return 'DiscoveryPage';
    }
    if (props.discoveryPageData.categoryId) {
      return 'CategoryPage';
    }
    return 'invalid';
  };

  const getUrl = (locale: string, absolute?: boolean) => {
    if (!props.discoveryPageData.categoryId) {
      return props.discoveryPageData.slugs[locale];
    }

    const safeSlug = isSlugEmpty(locale) ? '' : props.discoveryPageData.slugs[locale];

    return `${absolute ? 'https://' + domains[locale] : ''}${props.discoveryPageData.categoryUrl[locale]}/${safeSlug}`;
  };

  const impactedLocales: string[] = sdk.locales.available.filter((locale) => {
    return (
      isDiscoveryPageAvailable(props.discoveryPageData, locale) ||
      isDiscoveryPageAvailableThroughExperiment(props.discoveryPageData, locale)
    );
  });

  return (
    <Stack fullWidth={true} marginTop="spacingM">
      {impactedLocales.length === 0 ? (
        <></>
      ) : (
        <Accordion align="start" style={{ width: '100%' }}>
          <Accordion.Item title={<Caption>Additional information</Caption>}>
            {impactedLocales.map((locale) => {
              return (
                <Note
                  title={`Locale: ${locale}`}
                  key={`extended-${locale}`}
                  variant="neutral"
                  style={{ marginBottom: 5 }}>
                  <Caption>
                    <strong>Type:</strong> {getType(locale)}
                  </Caption>
                  <br />
                  <Caption>
                    <strong>Url Id:</strong> {getUrlId(locale)}
                  </Caption>
                  <br />
                  <Caption>
                    <strong>Url:</strong>
                    <a target="_blank" href={getUrl(locale, true)}>
                      {getUrl(locale)}
                    </a>
                  </Caption>
                </Note>
              );
            })}
          </Accordion.Item>
        </Accordion>
      )}
    </Stack>
  );
};

export default ExtendedInformation;
