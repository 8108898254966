import { UrlServiceResponse } from '../types/UrlServiceResponse';

export default class UrlServiceClient {
  private REQUESTOR: string = 'discovery-pages-status-app';
  private URL_SERVICE_BASE_URL: string = 'https://url.prod.merch.vpsvc.com/v3';

  async getCategoryUrl(tenant: string, locale: string, categoryId: string): Promise<string> {
    try {
      const requestUrl = `${this.URL_SERVICE_BASE_URL}/url/${tenant}/${locale}/${categoryId}?requestor=${this.REQUESTOR}`;
      const response: Response = await fetch(requestUrl);
      if (response.status === 200) {
        const json: any = await response.json();
        return json.url;
      }
      return '';
    } catch {
      throw new Error('Error calling URL Service');
    }
  }

  async getUrlId(tenant: string, locale: string, url: string): Promise<UrlServiceResponse> {
    try {
      const requestUrl = `${this.URL_SERVICE_BASE_URL}/id/${tenant}/${locale}/${encodeURIComponent(url)}?requestor=${
        this.REQUESTOR
      }`;
      const response = await fetch(requestUrl);

      if (response.status === 404) {
        return { available: true };
      }

      if (response.status === 200) {
        const json: any = await response.json();

        return {
          available: false,
          url: json.url,
          id: json.id,
          type: json.type,
        };
      }

      throw new Error('Error calling URL Service');
    } catch {
      throw new Error('Error calling URL Service');
    }
  }
}
